<template>
    <b-col cols="6" class="d-flex justify-content-end">
        <b-input-group class="input-group-merge" style="margin: auto; margin-left: 20px;">
            <b-form-input
                id="global-search"
                type="search"
                placeholder="ابحث عن منتج محدد"
                v-model="mainDataFilter.search"
                @input="filterdData(1)"
            />
            <b-input-group-append is-text>
                <unicon width="16" height="16" fill="gray" name="search" />
            </b-input-group-append>
        </b-input-group>
         <b-button size="sm" variant="primary" class="text-nowrap" @click="setIsCreateProductModal(true)"> 
            منتج جديد
            <unicon class="ml-1" name="plus" width="18" height="18" fill="#fff" />
        </b-button>
        <createProductModal />
    </b-col>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import createProductModal from "./create-product-modal";

export default {
    components: {
        createProductModal
    },
    computed: {
        ...mapGetters(["mainDataFilter"])
    },
    methods: {
        ...mapActions(['setIsCreateProductModal', 'filterdData'])
    },
    beforeDestroy() {
      this.mainDataFilter.search = "";
    },
}
</script>