<template>
  <div>
    <ValidationObserver ref="observer">
      <b-form @submit.prevent="onSubmit">
        <b-sidebar bg-variant="white" v-model="is" backdrop shadow>
          <template #header>
            <div
              class="d-flex justify-content-between align-items-center px-1 py-50 bg-light"
            >
              <h5 class="m-0">
                <strong>إضافة منتج</strong>
              </h5>
              <b-button
                @click="is = false"
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
              >
                <unicon name="times" width="16" height="16" />
              </b-button>
            </div>
          </template>
          <b-col cols="12">
            <div class="d-flex justify-content-between py-1">
              <div class="d-flex">
                <b-form-checkbox v-model="productDto.isHidden"></b-form-checkbox>
                <label>مخفي</label>
              </div>
              <div class="d-flex">
                <b-form-checkbox v-model="productDto.certified"></b-form-checkbox>
                <label>معتمد</label>
              </div>
            </div>

            <BTextInputWithValidation
              rules="required"
              label="اسم المنتج"
              name="اسم المنتج"
              v-model="productDto.name"
              placeholder="أدخل اسم المنتج"
            />

            <b-form-group label="التصنيفات">
              <validation-provider #default="{ errors }" name="التصنيف" rules="required">
                <v-select
                  v-model="productDto.categoryBreakId"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="name"
                  :options="productCategoriesList"
                  :reduce="(option) => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <BTextInputWithValidation
              rules="required|positive"
              type="number"
              label="سعر المنتج SP"
              name="سعر المنتج SP"
              :value="SPPrice"
              @input="updateSPPrice($event)"
              placeholder="أدخل السعر"
            />
            <BTextInputWithValidation
              rules="required|positive|regex:[0-9.]"
              label="سعر المنتج $"
              name="سعر المنتج $"
              :value="DOLPrice"
              @input="updateDOlPrice($event)"
              placeholder="أدخل السعر"
            />
            <div class="editor-container">
              <label>شرح المنتج</label>
              <quill-editor
                class="editor"
                ref="myTextEditor"
                :value="productDto.description"
                :options="editorOption"
                @change="onEditorChange"
              />
            </div>
            <label>صورة</label>
            <fileDragDrop
              @inValidFile="unValidThumbImage"
              @uploaded="uploadThumbImage"
              id="employee-photo"
              height="100px"
              type="image"
              title="صورة جديدة"
            />
            <img
              v-if="!!productDto.imageDto.base64"
              :src="productDto.imageDto.base64"
              style="max-height: 320px; object-fit: fill"
              class="w-100"
            />
            <small class="text-danger" v-show="!productDto.imageDto.base64">
              صورة المنتج مطلوبة
            </small>
          </b-col>
          <template #footer>
            <div class="d-flex p-1 border-top">
              <b-button variant="outline-primary" class="ml-auto mr-1">إلغاء</b-button>
              <b-button variant="primary" type="submit">تم</b-button>
            </div>
          </template>
        </b-sidebar>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import hljs from "highlight.js";
import debounce from "lodash/debounce";

import { required } from "@validations";

export default {
  components: {
    BTextInputWithValidation,
    ValidationObserver,
    ValidationProvider,
    fileDragDrop,
    quillEditor,
    vSelect,
  },
  data: () => ({
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
          ["link", "image", "video"],
        ],
        syntax: {
          highlight: (text) => hljs.highlightAuto(text).value,
        },
      },
      placeholder: "ادخل شرح عن المنتجات",
    },
    required,
    productDto: {
      name: "",
      description: "",
      isHidden: false,
      certified: true,
      imageDto: {
        base64: "",
      },
      categoryBreakId: null,
      mainCategory: "",
      subCategory: "",
    },
    SPPrice: 0,
    DOLPrice: 0,
  }),
  computed: {
    ...mapGetters({
      productDetails: "productDetails",
      productCategoriesList: "productCategoriesList",
      lang: "app/lang",
      dolorPrice: "dolorPrice",
    }),
    editor() {
      return this.$refs.myTextEditor.quill;
    },
    is: {
      get() {
        return this.$store.getters["isCreateProductModal"];
      },
      set(is) {
        this.$store.dispatch("setIsCreateProductModal", is);
      },
    },
  },
  methods: {
    updateSPPrice(val) {
      this.SPPrice = val;
      this.DOLPrice = val / this.dolorPrice;
    },
    updateDOlPrice(val) {
      this.DOLPrice = val;
    },
    onEditorChange: debounce(function (value) {
      this.productDto.description = value.html;
    }, 466),
    ...mapActions(["setNewProduct"]),
    onSubmit() {
      this.$refs.observer.validate().then((success) => {
        if (success && this.productDto.imageDto.base64) {
          this.setNewProduct({
            id: 0,
            name: this.productDto.name,
            description: this.productDto.description,
            isHidden: this.productDto.isHidden,
            certified: this.productDto.certified,
            imageDto: {
              id: 0,
              path: null,
              base64: this.productDto.imageDto.base64,
            },
            language: this.lang,
            categoryBreakId: this.productDto.categoryBreakId,
            mainCategory: "",
            subCategory: "",
            sypPrice: +this.SPPrice,
            usdPrice: +this.SPPrice,
          }).then(() => {
            this.resetForm();
          });
        }
      });
    },
    resetForm() {
      this.SPPrice = 0;
      this.DOLPrice = 0;
      Object.assign(this.productDto, {
        id: 0,
        name: "",
        description: "",
        isHidden: false,
        certified: true,
        imageDto: {
          id: 0,
          path: "",
          base64: "",
        },
        linkId: 0,
        language: 0,
        categoryBreakId: null,
        mainCategory: "",
        subCategory: "",
        sypPrice: 0,
        usdPrice: 0,
      });
      Object.assign(this.productDetails, {
        id: 0,
        name: "",
        description: "",
        isHidden: false,
        certified: true,
        imageDto: {
          id: 0,
          path: "",
          base64: "",
        },
        linkId: 0,
        language: 0,
        categoryBreakId: null,
        mainCategory: "",
        subCategory: "",
        sypPrice: 0,
        usdPrice: 0,
      });
    },
    uploadThumbImage(file) {
      this.productDto.imageDto.base64 = file[0].base64;
    },
    unValidThumbImage() {
      console.log("un valid Thumb file");
    },
  },
};
</script>
